import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('authen/login', params);
}

export const getListAccount = (params) => {
    return axiosClient.post('cms/account/getListAccount', params);
}

export const addNewAccount = (params) => {
    return axiosClient.post('cms/account/addNewAccount', params);
}

export const getListDepartment = (params) => {
    return axiosClient.get(`cms/department/getListDepartment?keyword=${params?.keyword ?? ''}`);
}

export const getListRoles = () => {
    return axiosClient.get('cms/roles/getListRoles');
}

export const updateUserInfo = (params) => {
    return axiosClient.post('cms/account/updateUserInfo', params);
}

export const addNewDepartment = (params) => {
    return axiosClient.post('cms/department/addNewDepartment', params);
}

export const updateDepartmentById = (params) => {
    return axiosClient.post('cms/department/updateDepartmentById', params);
}

export const addNewSuppiler = (params) => {
    return axiosClient.post('cms/suppiler/addNewSuppiler', params);
}

export const updateSuppiler = (params) => {
    return axiosClient.post('cms/suppiler/updateSuppiler', params);
}

export const getListSuppiler = (params) => {
    return axiosClient.get(`cms/suppiler/getListSuppiler?keyword=${params?.keyword ?? ''}`);
}

export const getListOrders = (params) => {
    return axiosClient.post('cms/orders/getListOrders', params);
}

export const createNewTask = (params) => {
    return axiosClient.post('cms/tasks/createNewTask', params);
}

export const getListTaskByOrderId = (params) => {
    return axiosClient.get(`cms/tasks/getListTaskByOrderId?order_id=${params?.order_id}`);
}

export const updateTaskById = (params) => {
    return axiosClient.post('cms/tasks/updateTaskById', params);
}

export const updateOrderById = (params) => {
    return axiosClient.post('cms/orders/updateOrderById', params);
}

export const getListShops = (params) => {
    return axiosClient.get(`cms/shops/getListShops?name=${params?.name ?? ''}`);
}

export const getListTypeAction = (params) => {
    return axiosClient.get('cms/tasks/getListTypeAction');
}

export const updateShopById = (params) => {
    return axiosClient.post('cms/shops/deleteShopByShopId', params);
}

export const addNewTypeAction = (params) => {
    return axiosClient.post('cms/tasks/addNewTypeAction', params);
}

export const updateTypeAction = (params) => {
    return axiosClient.post('cms/tasks/updateTypeAction', params);
}

export const getListTaskForDesigner = (params) => {
    return axiosClient.post('cms/tasks/getListTaskForDesigner', params);
}

export const getListStaff = () => {
    return axiosClient.get('cms/tasks/getListStaff');
}

export const createNewOrder = (params) => {
    return axiosClient.post('cms/orders/createNewOrder', params);
}

export const convertImageToBase64 = (params) => {
    return axiosClient.get(`convertImageToBase64?image_url=${params?.image_url}`);
}

export const checkAndUpdateSKUForOrder = (params) => {
    return axiosClient.post('cms/orders/checkAndUpdateSKUForOrder', params);
}

export const updateDesignLinkForOrder = (params) => {
    return axiosClient.post('cms/orders/updateDesignLinkForOrder', params);
}

export const reportOrderGroupByShop = (params) => {
    return axiosClient.post('cms/reports/reportOrderGroupByShop', params);
}

export const reportTaskByStatus = (params) => {
    return axiosClient.post('cms/reports/reportTaskByStatus', params);
}

export const reportTaskByStaff = (params) => {
    return axiosClient.post('cms/reports/reportTaskByStaff', params);
}

export const exportOrderBySupplier = (params) => {
    return axiosClient.post('cms/reports/exportOrderBySupplier', params);
}

export const exportOrderBySelected = (params) => {
    return axiosClient.post('cms/reports/exportOrderBySelected', params);
}

export const changeUserPassword = (param) => {
    return axiosClient.post('authen/changePassword', param);
}

export const updateForMutilOrder = (param) => {
    return axiosClient.post('cms/orders/updateForMutilOrder', param);
}

export const updateForMutilTask = (param) => {
    return axiosClient.post('cms/tasks/updateForMutilTask', param);
}

export const updateSKUInfoToGoogleSheet = (param) => {
    return axiosClient.post('cms/tasks/updateSKUInfoToGoogleSheet', param);
}

export const getOrderDetailById = (params) => {
    return axiosClient.get(`cms/orders/getOrderDetailById?id=${params?.id}`);
}

export const updateShippingInfoByOrderId = (params) => {
    return axiosClient.post(`cms/orders/updateShippingInfoByOrderId`, params);
}

export const getListApiKey = (params) => {
    return axiosClient.get(`cms/api_key/getListApiKey?site=${params?.keyword}`);
}

export const deleteApiKey = (params) => {
    return axiosClient.post(`cms/api_key/deleteApiKey`, params);
}

export const editApiKey = (params) => {
    return axiosClient.post(`cms/api_key/editApiKey`, params);
}

export const addNewApiKey = (params) => {
    return axiosClient.post(`cms/api_key/addNewApiKey`, params);
}

export const getListCategories = (params) => {
    return axiosClient.get(`cms/categories/getListCategories?keyword=${params?.keyword ?? ''}`);
}

export const saveLogChangeStatus = (params) => {
    return axiosClient.post(`cms/logs/saveLogChangeStatus`, params);
}

export const getListLogChangeStatus = (params) => {
    return axiosClient.get(`cms/logs/getListLogByOrderId?order_id=${params?.order_id}`);
}

export const getListProvider = (params) => {
    return axiosClient.get(`cms/provider/getListProvider?keyword=${params?.keyword ?? ''}`);
}