import { Button, Col, Form, Row, Space, theme, Table, DatePicker, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';
import { filterOption } from '../../utils/funcHelper';
import { DATE_FORMAT, requiredItem } from '../../constants/constants';
import { exportOrderBySupplier, getListOrders, getListSuppiler } from '../../services/apis';
import FooterTable from '../../components/FooterTable';

const ReportOrderBySuppiler = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { RangePicker } = DatePicker;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSuppliers, setDataSuppliers] = useState([]);
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Order ID',
            dataIndex: 'order_key',
            key: 'order_key',
        },
        {
            title: 'Product name',
            dataIndex: 'item_name',
            key: 'item_name',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Design link',
            dataIndex: 'design_link',
            key: 'design_link',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Supplier',
            dataIndex: 'suppiler_name',
            key: 'suppiler_name',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };

    const getDataReport = async (page, startTime, endTime) => {
        const params = {
            suppiler_id: form.getFieldValue('suppiler_id') ?? null,
            startTime: startTime,
            endTime: endTime,
            limit: 50,
            page: page
        }
        setLoading(true);
        const res = await getListOrders(params);
        consoleLogUtil('getDataReport', res);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data?.data]);
            setTotalRecords(res?.data?.totalRows);
            setTotalPage(res?.data?.totalPages);
        } else {
            setData([]);
        }
    }
    const handleExportToExcel = async () => {
        const params = {
            suppiler_id: form.getFieldValue('suppiler_id') ?? null,
            startTime: dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT),
            endTime: dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT),
        }
        setLoading(true);
        const res = await exportOrderBySupplier(params);
        setLoading(false);
        if (res && res.status === 1) {
            message.success('Xuất excel thành công');
            window.open(res?.data?.download_link);
        } else {
            message.error(res?.message ?? 'Xuất excel thất bại');
        }
    }
    const getDataSuppiler = async () => {
        try {
            const params = {
                keyword: '',
            }
            setLoading(true);
            const res = await getListSuppiler(params);
            setLoading(false);
            if (res && res.status === 1) {
                setDataSuppliers([...res?.data]);
            } else {
                setDataSuppliers([]);
            }
        } catch (error) {
            setLoading(false);
            setDataSuppliers([]);
        }
        

    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const onFinish = async (dataForm) => {
        if (dataForm && dataForm?.date) {
            getDataReport(1, dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(1, dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };
    

    useEffect(() => {
        getDataSuppiler();
    }, []);
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'suppiler_id'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhà cung cấp'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataSuppliers?.map(item => ({ value: item?.id, label: item?.name }))}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={2}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button danger onClick={handleExportToExcel}>
                                Xuất Excel
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                rowClassName={() => 'custom-row'}
                scroll={{ x: 'max-content' }}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportOrderBySuppiler);