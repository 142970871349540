import { Button, Col, Form, Image, Input, message, Modal, Row, Select, Space, theme, Upload } from "antd"
import React, { useEffect, useState } from "react";
import { requiredItem, STATUS_ORDER, TYPE_MODAL } from "../../../constants/constants";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { useSelector } from "react-redux";
import LoadingBase from "../../../components/LoadingBase";
import { beforeUpload, getBase64 } from "../../../utils/funcHelper";
import { BASE_URL } from "../../../settings/config";

const ModalAddOrder = ({ visible, onCancel, onSave, data, title, type, lstSupplier }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [metaData, setMetaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [uploading, setUploading] = useState(false);
    const userReducer = useSelector((state) => state.userReducer?.data);

    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };
    
    const props = {
        action: BASE_URL + 'upload',
        onChange(info) {
            if (info.file.status === 'uploading') {
                setUploading(true);
                return;
            }
            if (info.file.status === 'done') {
                setUploading(false);
                setImageUrl(info.file?.response?.image_path);
            }
            if (info.file.status === 'error') {
                setUploading(false);
                message.error("Tải ảnh thất bại. Vui lòng thử lại")
            }
        },

    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const formatMetaData = (metaData) => {
        if (!metaData || metaData?.length === 0) return;
        let temp = metaData.split(';').map(item => {
            const [name, ...valueParts] = item.split(':'); // Lấy phần đầu là name, phần còn lại là value
            const value = valueParts.length > 1 && name.toLowerCase() === 'image'
                ? valueParts.join(':').trim() // Ghép lại toàn bộ phần sau ':' nếu là 'Image'
                : valueParts.join(':').trim(); // Ghép lại toàn bộ nếu không phải là 'Image'

            return { name: name?.trim(), value };
        });

        // Đưa phần tử có name là "Image" xuống dưới cùng
        temp = temp.sort((a, b) => {
            if (a.name.toLowerCase() === 'image') return 1;
            if (b.name.toLowerCase() === 'image') return -1;
            return 0;
        });
        setMetaData([...temp]);
    }
    const handleChangeMetaData = (e) => {  
        const value = e.target.value;
        formatMetaData(value);
    }
    const handleFinish = (values) => {
        if (type === TYPE_MODAL.ADD) {
            if (!imageUrl) {
                message.error('Vui lòng chọn ảnh sản phẩm');
                return;
            }
            if (uploading) {
                message.error('Đang tải ảnh lên. Vui lòng đợi');
                return;
            }
            const temp = { ...values, image_src: imageUrl};
            onSave(temp);
        }
    };
    const handleValuesChange = (changedValues, allValues) => {
        if (changedValues.quantity || changedValues.price) {
            const total = (allValues.quantity || 0) * (allValues.price || 0);
            form.setFieldsValue({ total });
        }
    };
    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
            
        } else {
            form.resetFields();
            setImageUrl(null);
            setMetaData([]);
        }
    }, [data, type]);

    return (
        <Modal
            title={title || 'Chi tiết đơn hàng'}
            open={visible}
            onCancel={onCancel}
            width={'80%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-order" style={formStyle} onFinish={handleFinish} onValuesChange={handleValuesChange}>
                <Row gutter={16}>
                    <div
                        style={{ width: 130, height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Upload
                            {...props}
                            maxCount={1}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            accept='image/png,image/jpg,image/jpeg'
                            beforeUpload={beforeUpload}
                        >
                            {imageUrl ? (
                                <img
                                    src={BASE_URL + imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, paddingRight: 12, flex: 1, borderRight: '1px solid #CCC' }}>
                        <Form.Item
                            name={'item_name'}
                            rules={requiredItem}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Tên sản phẩm</span>}
                        >
                            <Input className="cus-input" style={{ height: 30 }} />
                        </Form.Item>

                        <Form.Item
                            name={'status'}
                            label={<span className={'cus-input-label'}>Trạng thái</span>}
                            style={{ marginTop: -12 }}
                        >
                            <Select
                                style={{ width: 200 }}
                                placeholder="Chọn trạng thái"
                                options={STATUS_ORDER}

                            />
                        </Form.Item>

                    </div>
                    <div style={{ width: 290, marginLeft: 12, display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: 16, fontWeight: 600 }}>Thông tin store</p>
                        <Form.Item
                            name={'store_name'}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Tên store</span>}
                        >
                            <Input className="cus-input" style={{ height: 30 }} />
                        </Form.Item>
                        <Form.Item
                            name={'site'}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Link store</span>}
                            style={{ marginTop: -12 }}
                        >
                            <Input className="cus-input" style={{ height: 30 }} />
                        </Form.Item>
                    </div>
                </Row>

                <Row gutter={16} style={{ marginTop: 24 }}>
                    <div style={{ flex: 1, flexDirection: 'column' }} >
                        <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Order Info</p>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name={'order_id'}
                                    rules={requiredItem}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Origin ID</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'sku'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>SKU</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            <Col span={12}>
                                <Form.Item
                                    name={'total'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Total</span>}
                                >
                                    <Input className="cus-input" disabled style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'quantity'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Qty</span>}
                                >
                                    <Input type="number" className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'price'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Price</span>}
                                >
                                    <Input type="number" className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name={'supplier_id'}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Nhà cung cấp</span>}
                        >
                            <Select
                                placeholder="Chọn nhà cung cấp"
                                options={lstSupplier}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1, flexDirection: 'column', marginLeft: 32, marginRight: 32 }} >
                        <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Meta data</p>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={'meta_data'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Meta full</span>}
                                >
                                    <Input.TextArea className="cus-input" onChange={handleChangeMetaData} autoSize={{minRows: 3}}/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <div style={{ marginLeft: 8 }}>
                            {
                                metaData && metaData.length > 0 ?
                                    metaData.map((item, index) => (
                                        item?.name.toLowerCase() === 'size' ?
                                            <Row gutter={16} key={index}>
                                                <span><strong>{item?.name}:</strong> {item?.value}</span>
                                            </Row> :
                                            item?.name.toLowerCase() !== 'image' ?
                                                <Row gutter={16} key={index}>
                                                    <span><strong>{item?.name}:</strong> {item?.value}</span>
                                                </Row> :
                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: -8 }}>
                                                    <span><strong>{item?.name}:&nbsp;&nbsp;</strong></span>
                                                    <Image
                                                        src={item?.value}
                                                        style={{ width: 40, height: 40, marginTop: 8 }}
                                                    />
                                                </div>
                                    )) : null
                            }
                        </div>

                    </div>
                    <div style={{ flex: 1, flexDirection: 'column' }} >
                        <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Customer Info</p>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={'customer_name'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Customer name</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            <Col span={16}>
                                <Form.Item
                                    name={'customer_phone'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Phone</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            <Col span={24}>
                                <Form.Item
                                    name={'customer_address'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Shipping address</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>

                        </Row>
                    </div>
                </Row>

                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddOrder);

