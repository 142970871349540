import React from 'react';
import { Navigate } from 'react-router-dom';
import LayoutContent from './LayoutContent';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const PrivateRoute = ({ children, requiredRoles }) => {
    const userReducer = useSelector((state) => state?.userReducer);
    console.log('userReducer', userReducer);
    // Kiểm tra xem token có hết hạn hay không
    const isExpire = () => {
        if (userReducer && userReducer?.data && userReducer?.data?.access_token) {
            const decodedToken = jwtDecode(userReducer?.data?.access_token);
            if (decodedToken?.exp && moment(decodedToken.exp * 1000).isAfter(moment())) {
                return true;
            }
        }
        return false;
    };

    // Kiểm tra quyền của người dùng
    const hasPermission = () => {
        if (userReducer?.data?.roles) {
            const userRoles = userReducer.data.roles.map(role => parseInt(role.id));
            return requiredRoles?.some(role => userRoles.includes(role));
        }
        return false;
    };

    // Nếu token hết hạn hoặc không có quyền, chuyển hướng về trang đăng nhập
    if (!isExpire()) {
        return <Navigate to="/dang-nhap" />;
    }

    if (isExpire() && !hasPermission()) {
        return <Navigate to="*" />
    }

    return <LayoutContent>{children}</LayoutContent>;
};

export default PrivateRoute;
