import { Button, Col, Form, Input, Row, Space, theme, Table, message, Popconfirm, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import ModalAddAndEdit from './component/ModalAddAndEdit';
import { addNewAccount, getListAccount, getListDepartment, getListRoles, updateUserInfo } from '../../services/apis';
import { TYPE_MODAL } from '../../constants/constants';
import { filterOption } from '../../utils/funcHelper';

const ListAccountPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [titleModal, setTitleModal] = useState('');
    const [listDepartment, setListDepartment] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    const columns = [
        {
            title: 'STT',
            key: 'STT',
            width: 80,
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            width: 80,
            align: 'center',
            render: (text, record, index) => text,
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            key: 'username',
            render: (text, record, index) => <a onClick={() => handleEditAccount(record)} style={{paddingLeft: 12, paddingRight: 12}}>{text}</a>,
        },
        {
            title: 'Họ và tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text, record, index) => <div style={{paddingLeft: 12, paddingRight: 12}}>{text}</div>,
        },
        {
            title: 'Phòng ban',
            render: (text, record, index) => (
                <span style={{paddingLeft: 12, paddingRight: 12}}>{record?.departments?.length > 0 ? record?.departments?.map(item => item?.name).join(", ") : ''}</span>
            ),
        },
        {
            title: 'Quyền',
            render: (text, record, index) => (
                <span style={{paddingLeft: 12, paddingRight: 12}}>{record?.roles?.length > 0 ? record?.roles?.map(item => item?.name)?.join(", ") : ''}</span>
            ),
        },
        {
            title: 'Create At',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (text, record, index) => <div style={{paddingLeft: 12, paddingRight: 12}}>{text}</div>,
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            render: (text, record) => (
                record?.username !== 'admin' ?
                <Space size="middle">
                    <a onClick={() => handleEditAccount(record)} >Sửa</a>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xoá?"
                        onConfirm={() => handleDelete(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <a style={{color: 'red'}}>Xoá</a>
                    </Popconfirm>
                    
                </Space> : null
            ),
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };
    
    const handleEditAccount = (record) => {
        setItemSelected(record);
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal('Sửa tài khoản');
    }
    const handleAddAccount = () => {
        setTypeModal(TYPE_MODAL.ADD);
        setShowModal(true);
        setTitleModal('Thêm tài khoản');
    }
    const handleDelete = (record) => {
            const payload = {
                ...record,
                status: 2
            }
            callApiUpdateUser(payload);
    }

    const getDataDepartment = async () => {
        try {
            const res = await getListDepartment();
            if (res && res.status === 1) {
                setListDepartment(res?.data?.map(item => ({...item, value: item.id, label: item.name})));
            } else {
                setListDepartment([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getDataRoles = async () => {
        try {
            const res = await getListRoles();
            if (res && res.status === 1) {
                setListRoles(res?.data?.map(item => ({...item, value: item.id, label: item.role_name})));
            } else {
                setListRoles([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getDataAccount = async (page, showLoading) => {
        try {
            const params = {
                keyword: form.getFieldValue('name') ?? '',
                department_id: form.getFieldValue('department_id') ?? null,
                limit: 50,
                currentPage: page,
            }
            if (showLoading) setLoading(true);
            const res = await getListAccount(params);
            if (showLoading) setLoading(false);
            if (res && res.status === 1) {
                setData([...res?.data?.data]);
            } else {
                message.error(res.message);
                setData([]);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
        

    }

    const onFinish = async (dataForm) => {
        getDataAccount(1, true);
    };
    const callApiAddUser = async (data) => {
        try {
            setLoading(true);
            const res = await addNewAccount(data);  
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Thêm tài khoản thành công');
                getDataAccount(1, true);
            } else {
                message.error(res.message);
            }          
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const callApiUpdateUser = async (data) => {
        try {
            setLoading(true);
            const res = await updateUserInfo(data);
            setLoading(false);
            if (res && res.status === 1) {
                getDataAccount(1, true);
            } else {
                message.error(res.message);
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }
    const handleOnSave = async (data) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            callApiAddUser(data);
        } else {
            callApiUpdateUser(data);
        }

    }

    useEffect(() => {
        setLoading(true);
        Promise.all([getDataDepartment(), getDataRoles(), getDataAccount(1, false)]).finally(() => setLoading(false));
    }, [])

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Nhập tên tài khoản'} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="department_id"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn phòng'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={listDepartment}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button danger onClick={handleAddAccount}>
                                Thêm tài khoản
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <LoadingBase loading={loading} />
            <ModalAddAndEdit
                visible={showModal}
                onCancel={() => setShowModal(false)}
                data={itemSelected}
                title={titleModal}
                onSave={handleOnSave}
                lstDepartment={listDepartment}
                lstRoles={listRoles}
                type={typeModal}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListAccountPage);