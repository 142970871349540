import { Button, Col, Form, Input, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect } from "react";
import { requiredItem, TYPE_MODAL } from "../../../constants/constants";
import { filterOption } from "../../../utils/funcHelper";

const ModalAddEditTask = ({ visible, onCancel, onSave, data, type, dataDepartment, lstAction}) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    
    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };
    const handleFinish = (values) => {
        if (type === TYPE_MODAL.ADD) {
            onSave(values);
        } else {
            const tempData = {
                ...data,
                ...values
            };
            onSave(tempData);
        }
    };

    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
           
            form.setFieldsValue({
                department_id: dataDepartment?.length > 0 ? dataDepartment[1].value : null,
                title_task: data.title_task,
                task_content: data.task_content,
                type_action: data?.type_action?.toString()
            });
            
        } else {
            form.resetFields();
            form.setFieldsValue({
                department_id: dataDepartment?.length > 0 ? dataDepartment[1].value : null
            });
        }
    }, [data, type]);

    return (
        <Modal
            title={'Thêm công việc'}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-edit-account" style={formStyle} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={'department_id'}
                            style={{marginTop: 16}}
                            label={<span className={'cus-input-label'}>Phòng ban</span>}
                            rules={requiredItem}
                            
                        >
                            <Select
                                disabled
                                style={{width: '100%'}}
                                placeholder="Chọn phòng ban"
                                options={dataDepartment}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'type_action'}
                            style={{marginTop: 16}}
                            label={<span className={'cus-input-label'}>Loại công việc</span>}
                            rules={requiredItem}
                            
                        >
                            <Select
                                showSearch
                                style={{width: '100%'}}
                                placeholder="Chọn phòng ban"
                                options={lstAction}
                                filterOption={filterOption}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={'title_task'}
                            label="Tiêu đề công việc"
                            rules={requiredItem}
                        >
                            <Input  />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={'task_content'}
                            label="Nội dung công việc"
                        >
                            <Input.TextArea placeholder="Nhập nội dung công việc"  autoSize={{minRows: 4}}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddEditTask);

