import { Button, Col, Form, Row, Space, theme, Table, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';
import { filterOption } from '../../utils/funcHelper';
import { requiredItem } from '../../constants/constants';
import { getListShops, reportOrderGroupByShop } from '../../services/apis';

const ReportOrderByShop = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { RangePicker } = DatePicker;
    const [dataShops, setDataShops] = useState([]);
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Shop',
            dataIndex: 'store_name',
            key: 'shop_name',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12, fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Total order sync',
            dataIndex: 'total_orders',
            key: 'total_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Pending',
            dataIndex: 'pending_orders',
            key: 'pending_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Updating',
            dataIndex: 'updating_orders',
            key: 'updating_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Processing',
            dataIndex: 'processing_orders',
            key: 'processing_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Fullfillment',
            dataIndex: 'fullfillment_orders',
            key: 'fullfillment_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Hold',
            dataIndex: 'hold_orders',
            key: 'hold_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Cancelled',
            dataIndex: 'cancelled_orders',
            key: 'cancelled_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Refund',
            dataIndex: 'refund_orders',
            key: 'refund_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Resend',
            dataIndex: 'resend_orders',
            key: 'resend_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Shipping',
            dataIndex: 'shipping_orders',
            key: 'shipping_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Delivered',
            dataIndex: 'delivered_orders',
            key: 'delivered_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Failed delivery',
            dataIndex: 'failed_delivery_orders',
            key: 'failed_delivery_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
        {
            title: 'Picked',
            dataIndex: 'picked_orders',
            key: 'picked_orders',
            align: 'center',
            render: (text, record) => <div style={{fontWeight: record?.store_name === 'Total' ? '700' : '400' }}>{text}</div>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };

    const getDataReport = async (startTime, endTime) => {
        const params = {
            store_name: form.getFieldValue('store_name') ?? null,
            fromTime: startTime,
            toTime: endTime
        }
        setLoading(true);
        const res = await reportOrderGroupByShop(params);
        consoleLogUtil('getDataReport', res);
        setLoading(false);
        if (res && res.status === 1) {
            const total = res?.data?.reduce((acc, item) => {
                acc.total_orders += item.total_orders;
                acc.pending_orders += parseInt(item.pending_orders, 10);
                acc.updating_orders += parseInt(item.updating_orders, 10);
                acc.processing_orders += parseInt(item.processing_orders, 10);
                acc.fullfillment_orders += parseInt(item.fullfillment_orders, 10);
                acc.hold_orders += parseInt(item.hold_orders, 10);
                acc.cancelled_orders += parseInt(item.cancelled_orders, 10);
                acc.refund_orders += parseInt(item.refund_orders, 10);
                acc.resend_orders += parseInt(item.resend_orders, 10);
                acc.shipping_orders += parseInt(item.shipping_orders, 10);
                acc.delivered_orders += parseInt(item.delivered_orders, 10);
                acc.failed_delivery_orders += parseInt(item.failed_delivery_orders, 10);
                acc.picked_orders += parseInt(item.picked_orders, 10);
                return acc;
            }, {
                store_name: "Total",
                total_orders: 0,
                pending_orders: 0,
                updating_orders: 0,
                processing_orders: 0,
                fullfillment_orders: 0,
                hold_orders: 0,
                cancelled_orders: 0,
                refund_orders: 0,
                resend_orders: 0,
                shipping_orders: 0,
                delivered_orders: 0,
                failed_delivery_orders: 0,
                picked_orders: 0,
            });
            const tempData = [...res?.data];
            tempData.push(total);
            setData([...tempData]);
        } else {
            setData([]);
        }
    }
    const getDataShops = async () => {
        try {
            const res = await getListShops();
            consoleLogUtil('getListShops', res);
            if (res && res.status === 1) {
                setDataShops([...res?.data?.data]);
            } else {
                setDataShops([]);
            }
        } catch (error) {
            setDataShops([]);
        }
    }

    const onFinish = async (dataForm) => {
        if (dataForm && dataForm?.date) {
            getDataReport(dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };
    

    useEffect(() => {
        getDataShops();
    }, []);
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'store_name'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn  shop'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataShops?.map(item => ({ value: item?.name, label: item?.name }))}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={2}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                scroll={{ x: 1300 }}
            />
            
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportOrderByShop);