import { Button, Col, Form, Input, Row, Space, theme, Table, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import ModalAddAndEdit from './component/ModalAddAndEdit';
import { addNewApiKey, deleteApiKey, editApiKey, getListApiKey } from '../../services/apis';
import { TYPE_MODAL } from '../../constants/constants';

const ListApiKeyPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [titleModal, setTitleModal] = useState('');
    const columns = [
        {
            title: 'STT',
            key: 'STT',
            width: 80,
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'site',
            render: (text, record, index) => <a onClick={() => handleEditApiKey(record)} style={{paddingLeft: 12}}>{text}</a>,
        },
        {
            title: 'Consumer key',
            dataIndex: 'consumer_key',
            key: 'consumer_key',
            render: (text, record, index) => <span style={{paddingLeft: 12}}>{text}</span>,
        },
        {
            title: 'Consumer secret',
            dataIndex: 'consumer_secret',
            key: 'consumer_secret',
            render: (text, record, index) => <span style={{paddingLeft: 12}}>{text}</span>,
        },
        {
            title: 'Action',
            width: 120,
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEditApiKey(record)} >Sửa</a>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xoá?"
                        onConfirm={() => handleDeleteApiKey(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a style={{color: 'red', textDecorationLine: 'underline'}}>Xoá</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };
    
    const handleEditApiKey = (record) => {
        setItemSelected(record);
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal('Edit api key');
    }
    const handleDeleteApiKey = async (record) => {
        try {
            setLoading(true);
            const res = await deleteApiKey({id: record.id});
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Xoá thành công');
                getDataApiKey(true);
            } else {
                message.warning(res?.msg ?? 'Xoá thất bại');
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    }
    const handleAddAccount = () => {
        setTypeModal(TYPE_MODAL.ADD);
        setShowModal(true);
        setTitleModal('Add api key');
    }
   
    const getDataApiKey = async (showLoading) => {
        try {
            const params = {
                keyword: form.getFieldValue('name') ?? '',
            }
            if (showLoading) setLoading(true);
            const res = await getListApiKey(params);
            if (showLoading) setLoading(false);
            if (res && res.status === 1) {
                setData([...res?.data?.data]);
            } else {
                message.warning(res.msg ?? 'Không có dữ liệu');
                setData([]);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
        

    }

    const onFinish = async (dataForm) => {
        getDataApiKey(true);
    };
    const callApiAddApiKey = async (data) => {
        try {
            const res = await addNewApiKey(data);  
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Thêm mới thành công');
                getDataApiKey(true);
            } else {
                message.warning(res.msg ?? 'Thêm mới thất bại');
            }          
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const callApiUpdateApiKey = async (data) => {
        try {
            setLoading(true);
            const res = await editApiKey(data);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Cập nhật thành công');
                getDataApiKey(true);
            } else {
                message.warning(res.msg ?? 'Cập nhật thất bại');
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }
    const handleOnSave = async (data) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            callApiAddApiKey(data);
        } else {
            callApiUpdateApiKey(data);
        }

    }

    useEffect(() => {
        getDataApiKey(true)
    }, [])

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'site name'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                            <Button danger onClick={handleAddAccount}>
                                Add
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <ModalAddAndEdit
                visible={showModal}
                onCancel={() => setShowModal(false)}
                data={itemSelected}
                title={titleModal}
                onSave={handleOnSave}
                type={typeModal}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListApiKeyPage);