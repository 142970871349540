import { Button, Col, Form, Input, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect } from "react";
import { requiredItem } from "../../../constants/constants";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";

const ModalEditAddress = ({ visible, onCancel, onSave, data }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();

    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };
    const handleFinish = (values) => {
        const shipping_info = {
            first_name: values.first_name ?? '',
            last_name: values.last_name ?? '',
            phone: values.phone ?? '',
            address_1: values.address_1 ?? '',
            address_2: values.address_2 ?? '',
            city: values.city ?? '',
            state: values.state ?? '',
            postcode: values.postcode ?? '',
            country: values.country ?? '',
        }
        const tempData = {
            ...data,
            shipping_info: shipping_info,
            customer_address: values.customer_address ?? '',
            customer_phone: values.phone ?? ''
        };
        onSave(tempData);
    };

    useEffect(() => {
        if (form && data?.id) {
            form.setFieldsValue({
                first_name: data?.shipping_info?.first_name ?? '',
                last_name: data?.shipping_info?.last_name ?? '',
                phone: data?.shipping_info?.phone ?? '',
                address_1: data?.shipping_info?.address_1 ?? '',
                address_2: data?.shipping_info?.address_2 ?? '',
                city: data?.shipping_info?.city ?? '',
                state: data?.shipping_info?.state ?? '',
                postcode: data?.shipping_info?.postcode ?? '',
                country: data?.shipping_info?.country ?? '',
                customer_address: data?.customer_address ?? '',
            });
        }
    }, [data]);

    return (
        <Modal
            title={'Sửa địa chỉ'}
            open={visible}
            onCancel={onCancel}
            width={'70%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-edit-address" style={formStyle} onFinish={handleFinish}>

                <Row gutter={12}>
                    <Col span={5}>
                        <Form.Item
                            name={'first_name'}
                            label="First name"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'last_name'}
                            label="Last name"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'phone'}
                            label="Shipping phone"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name={'address_1'}
                            label="Address 1"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={8}>
                    <Form.Item
                            name={'address_2'}
                            label="Address 2"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'city'}
                            label="City"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'state'}
                            label="State"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'postcode'}
                            label="Shipping Code"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'country'}
                            label="Country"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                <Col span={12}>
                        <Form.Item
                            name={'customer_address'}
                            label="Shipping Address index"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalEditAddress);

