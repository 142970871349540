import { Button, Col, Form, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect } from "react";
import { STATUS_ORDER } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { checkUserIsSupplier } from "../../../utils/funcHelper";

const ModalSelectMutil = ({ visible, onCancel, onSave, lstSupplier }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const userReducer = useSelector((state) => state?.userReducer?.data);

    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };

    const handleFinish = (values) => {
        onSave(values);
    }
    useEffect(() => {
        form.resetFields();
    }, [visible]);
    return (
        <Modal
            title={'Choose information for update'}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-supplier" style={formStyle} onFinish={handleFinish}>
                <Row gutter={16} style={{ marginTop: 12 }}>
                    {
                        checkUserIsSupplier(userReducer) ?
                            null
                            :
                            <Col span={12}>
                                <Form.Item
                                    name={'supplier_id'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Supplier</span>}
                                >
                                    <Select
                                        placeholder="Select supplier"
                                        options={lstSupplier}
                                    />
                                </Form.Item>
                            </Col>
                    }

                    <Col span={12}>
                        <Form.Item
                            name={'status'}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Status</span>}
                        >
                            <Select
                                placeholder="Select status"
                                options={!checkUserIsSupplier(userReducer) ? STATUS_ORDER : STATUS_ORDER.filter(item => item.type.includes(3))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalSelectMutil);

