import {
    LogoutOutlined,
    FileSearchOutlined,
    ProjectOutlined,
    UsergroupAddOutlined,
    ApartmentOutlined,
    ReloadOutlined,
    ShopOutlined,
    TagOutlined,
    DiffOutlined,
    SolutionOutlined,
    SwitcherOutlined,
    ExportOutlined,
    ClockCircleOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined,
    HomeOutlined,
    KeyOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY",
    YYYYMMDDHHMMSS_FORMAT: "YYYY-MM-DD HH:mm:ss",
    YYYYMMDDHHMMSS_FORMAT_2: "YYYY/MM/DD HH:mm:ss",
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const PASSWORD_DEFAULT = '123456asdfwer3';

export const LIST_MENU_SIDE_BAR = [
    
    
    {
        key: 'account',
        path: "/account",
        label: 'Quản lý tài khoản',
        icon: <UsergroupAddOutlined />,
        permission: [1]
    },
    {
        key: 'department',
        path: "/department",
        label: 'Quản lý phòng ban',
        icon: <ApartmentOutlined />,
        permission: [1]
    },
    {
        key: 'type-task',
        path: "/type-task",
        label: 'Quản lý loại task',
        icon: <SwitcherOutlined />,
        permission: [1,3,4]
    },
    {
        key: 'supplier',
        path: "/supplier",
        label: 'Quản lý nhà cung cấp',
        icon: <ShopOutlined />,
        permission: [1,2]
    },
    {
        key: 'orders',
        path: "/orders",
        label: 'Quản lý order',
        icon: <TagOutlined />,
        permission: [1,2]
    },
    {
        key: 'fullfillment',
        path: "/fullfillment",
        label: 'Fullfillment',
        icon: <ExportOutlined />,
        permission: [1,2]
    },
    {
        key: 'for-supplier',
        path: "/for-supplier",
        label: 'For Supplier',
        icon: <ExportOutlined />,
        permission: [1,5]
    },
    {
        key: 'tasks',
        path: "/tasks",
        label: 'Quản lý task',
        icon: <DiffOutlined />,
        permission: [1,3,4]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-order',
                path: "/bao-cao-order",
                label: 'Báo cáo đơn hàng',
                icon: <FileSearchOutlined />,
                permission: [1, 2]
            },
            {
                key: 'bao-cao-task',
                path: "/bao-cao-task",
                label: 'Báo cáo Task',
                icon: <FileSearchOutlined />,
                permission: [1, 3]
            },
            {
                key: 'bao-cao-task-nv',
                path: "/bao-cao-task-nv",
                label: 'Báo cáo Task theo NV',
                icon: <SolutionOutlined />,
                permission: [1, 3, 4]
            },
            // {
            //     key: 'bao-cao-suppiler',
            //     path: "/bao-cao-suppiler",
            //     label: 'Báo cáo theo NCC',
            //     icon: <SolutionOutlined />,
            //     permission: [1, 2]
            // },
            
        ]
    },
    {
        key: 'store',
        path: "/store",
        label: 'Danh sách store',
        icon: <HomeOutlined />,
        permission: [1]
    },
    {
        key: 'api-key',
        path: "/api-key",
        label: 'Danh sách API Key',
        icon: <KeyOutlined />,
        permission: [1]
    },
    {
        key: 'doi-mat-khau',
        path: "/doi-mat-khau",
        label: 'Change Password',
        icon: <ReloadOutlined />,
        permission: [1,2,3,4, 5]
    },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Logout',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3, 4, 5]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];


export const FORMAT_DATE = {
    YYYYMMDDHHMMSS: 'YYYY-MM-DD HH:mm:ss',
    YYYMMDD: 'YYYY-MM-DD',
    DDMMYYYY: 'DD/MM/YYYY',
}

// export const STATUS_ORDER = [
//     {
//         value: 'waiting',
//         label: 'Chờ xử lý',
//         type: [1] // 1: là hiển thị trên list order, 2: hiển thị trên list fullfillment
//     },
//     {
//         value: 'completed',
//         label: 'Hoàn thành',
//         type: [1]
//     },
//     {
//         value: 'designing',
//         label: 'Đang thiết kế',
//         type: [1]
//     },
//     {
//         value: 'exporting',
//         label: 'Chờ gửi sản xuất',
//         type: [2]
//     },
//     {
//         value: 'producing',
//         label: 'Đang sản xuất',
//         type: [2]
//     },
    
//     {
//         value: 'shipping',
//         label: 'Đang vận chuyển',
//         type: [2]
//     },
//     {
//         value: 'cancelled',
//         label: 'Huỷ sản xuất',
//         type: [2]
//     },
//     {
//         value: 'pending',
//         label: 'Dừng sản xuất',
//         type: [2]
//     },
//     {
//         value: 'waiting_producing',
//         label: 'Chờ sản xuất',
//         type: [2]
//     },
//     {
//         value: 'ship_success',
//         label: 'Vận chuyển thành công',
//         type: [2]
//     },
//     {
//         value: 'ship_failed',
//         label: 'Vận chuyển thất bại',
//         type: [2]
//     },
//     {
//         value: 'product_cancel',
//         label: 'Huỷ sản xuất',
//         type: [2]
//     },
// ]
export const STATUS_ORDER = [
    {
        value: 'pending',
        label: 'Pending',
        type: [1]
    },
    {
        value: 'updating',
        label: 'Updating',
        type: [1]
    },
    {
        value: 'processing',
        label: 'Processing',
        type: [2,3]
    },
    {
        value: 'fullfillment',
        label: 'Fullfillment',
        type: [2]
    },
    {
        value: 'hold',
        label: 'Hold',
        type: [1,3]
    },
    {
        value: 'cancelled',
        label: 'Cancel',
        type: [1,3]
    },
    {
        value: 'refund',
        label: 'Refund',
        type: [1,3]
    },
    {
        value: 'resend',
        label: 'Resend',
        type: [1, 3]
    },
    {
        value: 'picked',
        label: 'Picked',
        type: [2]
    },
    {
        value: 'shipping',
        label: 'Shipping',
        type: [2,3]
    },
    {
        value: 'delivered',
        label: 'Delivered',
        type: [2,3]
    },
    {
        value: 'failed_delivery',
        label: 'Failed Delivery',
        type: [2,3]
    },
    {
        value: 'in_product',
        label: 'In Product',
        type: [2,3]
    },
    {
        value: 'waiting_tracking',
        label: 'Waiting add Tracking',
        type: [2,3]
    },
]
export const statusOrderMap = {
    // waiting: { icon: <ClockCircleOutlined spin />, color: "#b81c24", text: "Waiting" },
    pending: { icon: <ClockCircleOutlined />, color: "#f6941c", text: "Pending" },
    updating: { icon: <SyncOutlined />, color: "processing", text: "Updating" },
    processing: { icon: <SyncOutlined />, color: "processing", text: "Processing" },
    picked: { icon: <ExportOutlined />, color: "processing", text: "picked" },
    fullfillment: { icon: <CheckCircleOutlined />, color: "success", text: "Fullfillment" },
    hold: { icon: <CloseCircleOutlined />, color: "error", text: "Hold" },
    cancelled: { icon: <CloseCircleOutlined />, color: "error", text: "Cancel" },
    refund: { icon: <CloseCircleOutlined />, color: "error", text: "Refund" },
    resend: { icon: <CloseCircleOutlined />, color: "processing", text: "Resend" },
    shipping: { icon: <MinusCircleOutlined />, color: "processing", text: "Shipping" },
    delivered: { icon: <CheckCircleOutlined />, color: "success", text: "Delivered" },
    failed_delivery: { icon: <CloseCircleOutlined />, color: "error", text: "Failed Delivery" }
};
// export const statusOrderMap = {
//     pending: { icon: <ClockCircleOutlined />, color: "#f6941c", text: "Dừng sản xuất" },
//     designing: { icon: <SyncOutlined />, color: "processing", text: "Đang thiết kế" },
//     producing: { icon: <SyncOutlined />, color: "processing", text: "Đang sản xuất" },
//     completed: { icon: <CheckCircleOutlined />, color: "success", text: "Hoàn thành" },
//     cancelled: { icon: <CloseCircleOutlined />, color: "error", text: "Huỷ sản xuất" },
//     shipping: { icon: <MinusCircleOutlined />, color: "processing", text: "Đang vận chuyển" },
//     waiting: { icon: <ClockCircleOutlined spin />, color: "#b81c24", text: "Chờ xử lý" },
//     exporting: { icon: <ExportOutlined />, color: "#2f7b88", text: "Chờ gửi sản xuất" },
//     waiting_producing: { icon: <SyncOutlined />, color: "processing", text: "Chờ sản xuất" },
//     ship_success: { icon: <CheckCircleOutlined />, color: "success", text: "Vận chuyển thành công" },
//     ship_failed: { icon: <CloseCircleOutlined />, color: "error", text: "Vận chuyển thất bại" },
//     product_cancel: { icon: <CloseCircleOutlined />, color: "error", text: "Huỷ sản xuất" },
// };

export const STATUS_TASK = [
    {
        value: 'pending',
        label: 'Pending Assignment'
    },
    {
        value: 'waiting',
        label: 'Waiting Processing'
    },
    {
        value: 'processing',
        label: 'Processing'
    },
    {
        value: 'completed',
        label: 'Finished'
    },
    {
        value: 'cancelled',
        label: 'Cancelled'
    }
]

export const TYPE_EXPORT = [
    {
        value: 1,
        label: 'Đã có export time'
    },
    {
        value: 2,
        label: 'Chưa có export time'
    }
]

export const TYPE_EXPORT_TRACKING = [
    {
        value: 1,
        label: 'Have tracking number'
    },
    {
        value: 2,
        label: 'No tracking number'
    }
]

export const TYPE_TIME = [
    {
        value: 2,
        label: 'Export at'
    },
    {
        value: 1,
        label: 'Create at'
    }
]

export const PAGE_SIZE = [
    {
        value: 20,
        label: 20
    },
    {
        value: 30,
        label: 30
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 70,
        label: 70
    },
    {
        value: 100,
        label: 100
    }
]

