import { Button, Col, Row, Select, Space } from "antd"
import React from "react";
import { PAGE_SIZE } from "../constants/constants";

const FooterTable = ({ handleNext, handlePrev, totalPage, currentPage, totalRecord, children, onSelect, isShowPageSize, currentSize, onSelectSize }) => {

    return (
        <Row align={"middle"} >
            <Col span={4}>
                <span className="txt-title-footer">Tổng số bản ghi: {totalRecord}</span>
            </Col>
            {children}
            <Col span={children ? 10 : 20} style={{ justifyContent: 'end', display: 'flex' }}>
                <Space size="small">
                    {
                        isShowPageSize ?
                            <>
                                <span>Size:</span>
                                <Select
                                    defaultValue={1}
                                    value={currentSize}
                                    options={PAGE_SIZE}
                                    style={{ width: 70 }}
                                    onSelect={onSelectSize}
                                />
                            </>
                            : null
                    }
                    <span>Pages:</span>
                    <Select
                        defaultValue={1}
                        value={currentPage}
                        options={Array.from({ length: totalPage }, (_, index) => ({
                            value: index + 1,
                            label: index + 1
                        }))}
                        style={{ width: 60 }}
                        onSelect={onSelect}
                    />
                    <Button type="link" disabled={currentPage > 1 ? false : true} onClick={handlePrev}>
                        Trước
                    </Button>
                    <Button
                        disabled={currentPage < totalPage ? false : true}
                        type='link'
                        onClick={handleNext}
                    >
                        Sau
                    </Button>

                </Space>
            </Col>
        </Row>
    )
}
export default React.memo(FooterTable);