import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import DashboardPage from './screens/dashboard/DashboardPage';
import ListDepartmentPage from './screens/department/ListDepartmentPage';
import ListSuppilerPage from './screens/suppiler/ListSuppilerPage';
import ListOrderPage from './screens/order/ListOrderPage';
import ListTypaActionTaskPage from './screens/type-action/ListTypaActionTaskPage';
import ListTaskPage from './screens/task/ListTaskPage';
import { useSelector } from 'react-redux';
import ReportOrderByShop from './screens/report/ReportOrderByShop';
import ReportTaskByStatus from './screens/report/ReportTaskByStatus';
import ReportTaskByStaff from './screens/report/ReportTaskByStaff';
import ReportOrderBySuppiler from './screens/report/ReportOrderBySuppiler';
import ChangePassPage from './screens/changepass/ChangePassPage';
import ListExportSupplierPage from './screens/order/ListExportSupplierPage';
import ListShopPage from './screens/shop/ListShopPage';
import ListApiKeyPage from './screens/api-key/ListApiKeyPage';
import ListOrderForSupplierPage from './screens/order/ListOrderForSupplierPage';


const AppRouter = () => {
    const userReducer = useSelector(state => state?.userReducer?.data);
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/dang-nhap" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                        <PrivateRoute requiredRoles={[1,2,3,4,5]}>
                            {
                                
                                userReducer?.roles?.map(role => role.id)?.filter(item => item === "4" || item === "3")?.length > 0  ? <ListTaskPage /> : 
                                (
                                    userReducer?.roles?.map(role => role.id)?.filter(item => item === "5")?.length > 0 ? <ListOrderForSupplierPage /> : <ListOrderPage />
                                )
                                
                            }
                        </PrivateRoute>
                } />
                <Route path="/account" element={
                        <PrivateRoute requiredRoles={[1]}>
                            <ListAccountPage />
                        </PrivateRoute>
                } />
                <Route path="/department" element={
                        <PrivateRoute requiredRoles={[1]}> 
                            <ListDepartmentPage />
                        </PrivateRoute>
                } />
                <Route path="/supplier" element={
                        <PrivateRoute requiredRoles={[1, 2]}>
                            <ListSuppilerPage />
                        </PrivateRoute>
                } />
                <Route path="/orders" element={
                        <PrivateRoute requiredRoles={[1,2]}>
                            <ListOrderPage />
                        </PrivateRoute>
                } />
                <Route path="/type-task" element={
                        <PrivateRoute requiredRoles={[1,2,3]}>
                            <ListTypaActionTaskPage />
                        </PrivateRoute>
                } />
                <Route path="/tasks" element={
                        <PrivateRoute requiredRoles={[1,3, 4]}>
                            <ListTaskPage />
                        </PrivateRoute>
                } />
                <Route path="/bao-cao-order" element={
                        <PrivateRoute requiredRoles={[1,2]}>
                            <ReportOrderByShop />
                        </PrivateRoute>
                } />
                <Route path="/bao-cao-task" element={
                        <PrivateRoute requiredRoles={[1, 3]}>
                            <ReportTaskByStatus/>
                        </PrivateRoute>
                } />
                <Route path="/bao-cao-task-nv" element={
                        <PrivateRoute requiredRoles={[1, 3, 4]}>
                            <ReportTaskByStaff />
                        </PrivateRoute>
                } />
                <Route path="/bao-cao-suppiler" element={
                        <PrivateRoute requiredRoles={[1]}>
                            <ReportOrderBySuppiler />
                        </PrivateRoute>
                } />
                <Route path="/doi-mat-khau" element={
                        <PrivateRoute requiredRoles={[1,2,3,4,5]}>
                            <ChangePassPage />
                        </PrivateRoute>
                } />
                <Route path="/fullfillment" element={
                        <PrivateRoute requiredRoles={[1,2]}>
                            <ListExportSupplierPage />
                        </PrivateRoute>
                } />
                <Route path="/store" element={
                        <PrivateRoute requiredRoles={[1]}>
                            <ListShopPage />
                        </PrivateRoute>
                } />
                <Route path="/api-key" element={
                        <PrivateRoute requiredRoles={[1]}>
                            <ListApiKeyPage />
                        </PrivateRoute>
                } />
                <Route path="/for-supplier" element={
                        <PrivateRoute requiredRoles={[1,5]}>
                            <ListOrderForSupplierPage />
                        </PrivateRoute>
                } />
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;