import { Button, Col, Input, Modal, Row, Space } from "antd"
import React, { useState } from "react";

const ModalAddNote = ({ visible, onCancel, onSave }) => {
    const [reason, setReason] = useState('');
    const handleChange = (e) => {
        setReason(e.target.value);
    }

    const handleSave = () => {
        onSave(reason);
    }

    return (
        <Modal
            title={'Input reason for cancel'}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={null}
        >
                <Row>
                    <Col span={24}>
                        <Input.TextArea 
                            onChange={handleChange} 
                            value={reason} maxLength={400} 
                            autoSize={{minRows: 3}} 
                            placeholder="Input reason max 400 words"
                            style={{marginBottom: 20, marginTop: 10}}
                        />
                    </Col>
                </Row>
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" onClick={handleSave}>
                            Lưu
                        </Button>
                    </Space>
                </Row>
        </Modal>
    )
}
export default React.memo(ModalAddNote);

