import { Button, Col, Form, Input, Row, Space, theme, Table, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import ModalAddAndEdit from './component/ModalAddAndEdit';
import { addNewDepartment, addNewTypeAction, getListDepartment, getListTypeAction, updateDepartmentById, updateTypeAction } from '../../services/apis';
import { TYPE_MODAL } from '../../constants/constants';

const ListTypeActionTaskPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [titleModal, setTitleModal] = useState('');
    const columns = [
        {
            title: 'STT',
            key: 'STT',
            width: 80,
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: <span style={{paddingLeft: 12}}>Loại công việc</span>,
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text, record, index) => <a onClick={() => handleEditAccount(record)} style={{paddingLeft: 12}}>{text}</a>,
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            width: 120,
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEditAccount(record)} >Sửa</a>
                </Space>
            ),
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };
    
    const handleEditAccount = (record) => {
        setItemSelected(record);
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal('Sửa loại công việc');
    }
    const handleAddAccount = () => {
        setTypeModal(TYPE_MODAL.ADD);
        setShowModal(true);
        setTitleModal('Thêm loại công việc');
    }
   
    const getDataTypeAction = async (showLoading) => {
        try {
            const params = {
                keyword: form.getFieldValue('name') ?? '',
            }
            if (showLoading) setLoading(true);
            const res = await getListTypeAction(params);
            if (showLoading) setLoading(false);
            if (res && res.status === 1) {
                setData([...res?.data?.data]);
            } else {
                message.error(res.message);
                setData([]);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
        

    }

    const onFinish = async (dataForm) => {
        getDataTypeAction(true);
    };
    const callApiAddTypeAction = async (data) => {
        try {
            setLoading(true);
            const res = await addNewTypeAction(data);  
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Thêm mới thành công');
                getDataTypeAction(true);
            } else {
                message.error(res.message);
            }          
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const callApiUpdateTypeAction = async (data) => {
        try {
            setLoading(true);
            const res = await updateTypeAction(data);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Cập nhật thành công');
                getDataTypeAction(true);
            } else {
                message.error(res.message);
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }
    const handleOnSave = async (data) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            callApiAddTypeAction(data);
        } else {
            callApiUpdateTypeAction(data);
        }

    }

    useEffect(() => {
        getDataTypeAction(true)
    }, [])

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Nhập tên'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button danger onClick={handleAddAccount}>
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <LoadingBase loading={loading} />
            <ModalAddAndEdit
                visible={showModal}
                onCancel={() => setShowModal(false)}
                data={itemSelected}
                title={titleModal}
                onSave={handleOnSave}
                type={typeModal}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListTypeActionTaskPage);