import { Button, Col, DatePicker, Divider, Form, Image, Input, Modal, Row, Select, Space, Tag, theme, Tooltip } from "antd"
import React, { useEffect, useState } from "react";
import { DATE_FORMAT, requiredItem, STATUS_TASK, TYPE_MODAL } from "../../../constants/constants";
import { CalendarOutlined, CheckCircleFilled, CloseCircleOutlined, FileDoneOutlined, InfoCircleOutlined, LinkOutlined, OrderedListOutlined, RetweetOutlined, SyncOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import he from 'he';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { checkUserPermissionSelectStaff, copyToClipboard } from "../../../utils/funcHelper";
import { BASE_URL } from "../../../settings/config";
import { convertImageToBase64 } from "../../../services/apis";

const ModalTaskDetail = ({ visible, onCancel, onSave, data, title, type, lstStaff, lstAction }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [metaData, setMetaData] = useState([]);
    const [contentTask, setContentTask] = useState('');
    const userReducer = useSelector(state => state?.userReducer?.data);
    const isPermissionSelectStaff = checkUserPermissionSelectStaff(userReducer);
    const [imageBase64, setImageBase64] = useState({});


    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };

    const handleFinish = (values) => {
        if (type === TYPE_MODAL.ADD) {
            // onSave(values);
        } else {
            const size = metaData?.find(item => item?.name?.toLowerCase() === 'size')?.value;
            let tempData = {
                ...data,
                ...values,
                size: size ?? ''
            };
            onSave(tempData);
        }
    };

    const handleStatusCheckOrderExist = (value) => {
        if (value === "1") {
            return <CheckCircleFilled style={{ color: 'green', paddingLeft: 8 }} />
        }
        if (value === "0") {
            return <CloseCircleOutlined style={{ color: 'red', paddingLeft: 8 }} />
        }
        if (value === "2") {
            return <SyncOutlined spin style={{ color: 'orange', marginLeft: 8 }} />
        }
    }
    useEffect(() => {
        if (data) {
            setImageBase64({});
            form.setFieldsValue({
                assigned_user_id: data?.assigned_user_id,
                type_action: data?.type_action,
                start_time: data?.start_time ? dayjs(data?.start_time) : null,
                end_time: data?.end_time ? dayjs(data?.end_time) : null,
                status: data?.status,
                link_design: data?.link_design ?? '',
                note: data?.note ?? '',
                task_content: data?.task_content ?? '',
                assignAt: data?.assignAt ? dayjs(data?.assignAt) : null,
                mockup_front: data?.mockup_front ?? '',
                mockup_back: data?.mockup_back ?? '',
            });
            setContentTask(data?.task_content ?? '');
        }
        if (data?.meta_data && data?.meta_data.length > 0) {
            let temp = data.meta_data.split(';').map(item => {
                const [name, ...valueParts] = item.split(':'); // Lấy phần đầu là name, phần còn lại là value
                const value = valueParts.length > 1 && name.toLowerCase() === 'image'
                    ? valueParts.join(':').trim() // Ghép lại toàn bộ phần sau ':' nếu là 'Image'
                    : valueParts.join(':').trim(); // Ghép lại toàn bộ nếu không phải là 'Image'

                return { name: name?.trim(), value };
            });

            // Đưa phần tử có name là "Image" xuống dưới cùng
            temp = temp.sort((a, b) => {
                if (a.name.toLowerCase() === 'image') return 1;
                if (b.name.toLowerCase() === 'image') return -1;
                return 0;
            });
            setMetaData([...temp?.filter(item => item?.name !== 'Additional Product')]);
        }

    }, [data, type, visible]);

    useEffect(() => {
        // Tải Base64 cho mỗi ảnh khi `metaData` thay đổi
        metaData.forEach((item) => {
            if (item?.name.toLowerCase() === 'image' && item?.value) {
                convertImageToBase64({ image_url: item?.value }).then((res) => {
                    setImageBase64((prev) => ({
                        ...prev,
                        [item?.name]: res.base64_image,
                    }));
                });
            }
        });
    }, [metaData]);

    return (
        <Modal
            title={title || 'Chi tiết task'}
            open={visible}
            onCancel={onCancel}
            width={'80%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="edit-task" style={formStyle} onFinish={handleFinish}>
                <Divider orientation="left" style={{ paddingTop: 0, marginTop: 0, paddingLeft: 0, paddingRight: 500, color: '#6573ab' }}>Thông tin sản phẩm</Divider>
                <Row gutter={16}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 4 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <div
                                style={{ width: 115, height: 115, border: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Image
                                    src={BASE_URL + data?.image_src}
                                    style={{ width: 110, height: 110 }} />
                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, flex: 1, borderRight: '1px solid #CCC' }}>
                            <Tooltip title="Order id system">
                                <Tag onClick={() => copyToClipboard(data?.order_key)} color="#85cbf8" style={{ width: 110, marginTop: 6 }}>{data?.order_key ?? ''}</Tag>
                            </Tooltip>
                            <p style={{ fontWeight: '600' }}>Origin ID: <span style={{ fontWeight: '400' }}>{data?.order_id ?? ''}</span></p>
                            <p style={{ fontWeight: '600' }}>SKU: <span style={{ fontWeight: '400' }}>{data?.sku ?? ''}{handleStatusCheckOrderExist(data?.is_exist)}</span></p>
                            <p style={{ fontWeight: '600' }}>Store: <span style={{ fontWeight: '400' }}>{data?.store_name ?? ''}</span></p>
                            <p style={{ fontWeight: '600' }}>Product ID: <span style={{ fontWeight: '400' }}>{data?.product_id ?? ''}</span></p>
                        </div>
                    </div>

                    <div style={{ flex: 3, marginLeft: 12, display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: 16, fontWeight: 600 }}><FileDoneOutlined /> Meta data</p>
                        <div style={{ marginLeft: 8 }}>
                            {
                                metaData && metaData.length > 0 ?
                                    metaData.map((item, index) => (
                                        item?.name.toLowerCase() === 'size' ?
                                            <Row gutter={16} key={index}>
                                                <span><span style={{ fontWeight: '600' }}>{item?.name}:</span> {item?.value}</span>
                                            </Row> :
                                            item?.name.toLowerCase() !== 'image' ?
                                                <Row gutter={16} key={index}>
                                                    <span><span style={{ fontWeight: '600' }}>{item?.name}:</span> {item?.value}</span>
                                                </Row> :
                                                (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: -8 }}>
                                                        <span><strong>{item?.name}:&nbsp;&nbsp;</strong></span>
                                                        {imageBase64[item?.name] ? (
                                                            <Image
                                                                src={`data:image/png;base64,${imageBase64[item?.name]}`}
                                                                style={{ width: 40, height: 40, marginTop: 8 }}
                                                            />
                                                        ) : (
                                                            <span>Loading...</span> // Hiển thị khi Base64 chưa có
                                                        )}
                                                    </div>
                                                )
                                    )) : null
                            }
                        </div>
                    </div>

                    <div style={{ flex: 3, marginLeft: 12, display: 'flex', flexDirection: 'column', borderLeft: '1px solid #CCC', paddingLeft: 12 }}>
                        <p style={{ fontSize: 16, fontWeight: 600 }}><InfoCircleOutlined /> Information checked</p>
                        {
                            data?.is_exist === "1" ?
                                <>
                                    <p><span style={{ fontWeight: '600' }}>Old Design:</span> {data?.order_design_link ? <a target="_blank" style={{ textDecorationLine: 'underline' }} href={data?.order_design_link}>Link</a> : <span style={{ color: 'red' }}>Không có</span>}</p>
                                    <p><span style={{ fontWeight: '600' }}>Old Custom name:</span> {data?.old_custom_name ?? ''}</p>
                                    <p><span style={{ fontWeight: '600' }}>Old Meta data:</span> {data?.old_meta_data ?? ''}</p>
                                </> :
                                <p>Không có dữ liệu</p>
                        }
                    </div>
                </Row>
                <Divider orientation="left" style={{ paddingTop: 0, marginTop: 20, paddingLeft: 0, paddingRight: 500, color: '#6573ab' }}>Thông tin task</Divider>
                <Row gutter={16} >
                    <Col span={24}>
                        <p style={{ fontSize: 20, color: 'black', fontWeight: '700' }}>{data?.title_task ?? ''}</p>
                        <Row>
                            <p><UserOutlined /> {data?.creator_fullname ?? ''}</p>
                            <p style={{ paddingLeft: 12 }}><CalendarOutlined /> {dayjs(data?.createAt).format(DATE_FORMAT.DD_MM_YYYY_FORMAT)}</p>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="task_content"
                                    label={<p> Mô tả công việc</p>}
                                    style={{ marginTop: 12 }}
                                >
                                    <Input.TextArea
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        disabled
                                        value={contentTask}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="note"
                                    label={<p> Ghi chú</p>}
                                    style={{ marginTop: 12 }}
                                >
                                    <Input.TextArea
                                        placeholder="Nhập ghi chú"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <p style={{ fontSize: 16, fontWeight: '700', color: 'red' }}>Cập nhật công việc</p>
                <Row gutter={16}>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="assigned_user_id"
                                    label={<p><UserOutlined /> Người thực hiện</p>}
                                    rules={requiredItem}
                                >
                                    <Select
                                        placeholder="Chọn nhân viên"
                                        style={{ width: '100%' }}
                                        options={lstStaff}
                                        disabled={!isPermissionSelectStaff}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="type_action"
                                    label={<p><OrderedListOutlined /> Loại công việc</p>}
                                >
                                    <Select
                                        placeholder="Chọn loại"
                                        style={{ width: '100%' }}
                                        options={lstAction}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="assignAt"
                                    label={<p><CalendarOutlined /> Assign At</p>}
                                    rules={requiredItem}
                                >
                                    <DatePicker style={{ width: '100%' }}
                                        disabled={!isPermissionSelectStaff}
                                        showTime={{ format: 'HH:mm' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="status"
                                    label={<p><RetweetOutlined /> Trạng thái</p>}
                                >
                                    <Select
                                        placeholder="Chọn trạng thái"
                                        style={{ width: '100%' }}
                                        options={isPermissionSelectStaff ? STATUS_TASK : STATUS_TASK.filter(item => item.value !== 'pending')}

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="start_time"
                                    label={<p><CalendarOutlined /> Start date</p>}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="end_time"
                                    label={<p><CalendarOutlined /> End date</p>}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mockup_front"
                                    label={<p><LinkOutlined /> Link mockup front</p>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mockup_back"
                                    label={<p><LinkOutlined /> Link mockup back</p>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="link_design"
                                    label={<p><LinkOutlined /> Link thiết kế</p>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalTaskDetail);

