import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect } from "react";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { requiredItem, STATUS_TASK } from "../../../constants/constants";
import { CalendarOutlined, OrderedListOutlined, RetweetOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const ModalAssignTask = ({ visible, onCancel, onSave, lstStaff, lstAction }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();

    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };

    const handleFinish = (values) => {
        consoleLogUtil('values', values);
        onSave(values);
    }
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            start_time: dayjs(),
            status: 'waiting',
            assignAt: dayjs()
        })
    }, [visible]);
    return (
        <Modal
            title={'Chọn thông tin task'}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-supplier" style={formStyle} onFinish={handleFinish}>


                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="assigned_user_id"
                            label={<p><UserOutlined /> Người thực hiện</p>}
                            rules={requiredItem}
                        >
                            <Select
                                placeholder="Chọn nhân viên"
                                style={{ width: '100%' }}
                                options={lstStaff}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="type_action"
                            label={<p><OrderedListOutlined /> Loại công việc</p>}
                            rules={requiredItem}
                        >
                            <Select
                                placeholder="Chọn loại"
                                style={{ width: '100%' }}
                                options={lstAction}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="start_time"
                            label={<p><CalendarOutlined /> Start date</p>}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="end_time"
                            label={<p><CalendarOutlined /> End date</p>}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label={<p><RetweetOutlined /> Trạng thái</p>}
                        >
                            <Select
                                placeholder="Chọn trạng thái"
                                style={{ width: '100%' }}
                                options={STATUS_TASK}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="assignAt"
                            label={<p><CalendarOutlined /> Assign At</p>}
                            rules={requiredItem}
                        >
                            <DatePicker style={{ width: '100%' }}
                                showTime={{ format: 'HH:mm' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                

                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAssignTask);

