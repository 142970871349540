import { Button, Col, Form, Input, Modal, Row, Space, theme } from "antd"
import React, { useEffect } from "react";
import { requiredItem, TYPE_MODAL } from "../../../constants/constants";

const ModalAddAndEdit = ({ visible, onCancel, onSave, data, title, type }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleFinish = (values) => {
        if (type === TYPE_MODAL.ADD) {
            onSave(values);
        } else {
            let tempData = {...values};
            tempData.id = data.id;
            onSave(tempData);
        }
    };
    
    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
            form.setFieldsValue({
                site: data.site,
                consumer_key: data.consumer_key,
                consumer_secret: data.consumer_secret,
            });
        } else {
            form.resetFields();
        }
    }, [data, type]);

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'50%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-edit-account" style={formStyle} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Site"
                            name="site"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Consumer key"
                            name="consumer_key"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Consumer secret"
                            name="consumer_secret"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddAndEdit);

