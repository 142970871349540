import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect } from "react";
import { PASSWORD_DEFAULT, requiredItem, TYPE_MODAL } from "../../../constants/constants";

const ModalAddAndEdit = ({ visible, onCancel, onSave, data, title, type, lstDepartment, lstRoles }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleFinish = (values) => {
        console.log(values);
        if (type === TYPE_MODAL.ADD) {
            onSave(values);
        } else {
            let tempData = {...values};
            if (values.password === PASSWORD_DEFAULT) {
                delete tempData.password;
            }
            tempData.id = data.id;
            onSave(tempData);
        }
    };
    
    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
            form.setFieldsValue({
                username: data.username,
                fullname: data.fullname,
                password: PASSWORD_DEFAULT,
                departments: data.departments.map(item => item.id),
                roles: data.roles.map(item => item.id),
            });
        } else {
            form.resetFields();
        }
    }, [data, type, visible]);

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'80%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-edit-account" style={formStyle} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Tên tài khoản"
                            name="username"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Họ và tên"
                            name="fullname"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={requiredItem}
                        >
                            <Input type="password"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Phòng ban"
                            name="departments"
                            rules={requiredItem}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: '100%', height: 40 }}
                                placeholder="Chọn phòng ban"
                                maxTagCount={'responsive'}
                                options={lstDepartment}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Quyền"
                            name="roles"
                            rules={requiredItem}
                        >
                            <Checkbox.Group options={lstRoles} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddAndEdit);