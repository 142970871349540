import axios from 'axios';
import { BASE_URL } from '../settings/config';
import TokenProvider from '../utils/TokenProvider';
import { message } from 'antd';


const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 100000, // 100 seconds
});
function apiLogger(msg, color, params) {
    console.log(msg, color, params);
}
axiosClient.interceptors.request.use(
    async config => {
        const token = TokenProvider.getToken();
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    error => {
        apiLogger(
            `%c FAILED ${error.response.method?.toUpperCase()} from ${error.response.config.url
            }:`,
            'background: red; color: #fff',
            error,
        );
        return Promise.reject(error);
    },
);
axiosClient.interceptors.response.use(
    (res) => {
        console.log("response", res);
        return res.data
    },
    async error => {
        
        const {response} = error || {};
        const {data, status} = response || {};
        const {msg} = data || {};
        console.log("ERRRR", response);
        apiLogger(
          `%c FAILED ${error.config?.method?.toUpperCase()} from ${
            error?.config?.url
          }:`,
          'background: red; color: #fff',
          error.response,
        );
        if (status === 401) {
            console.log("vao day 401")
          TokenProvider.logOut();
          message.warning("Hết phiên làm việc. Vui lòng đăng nhập lại");
          // return Promise.reject(error);
        }
        if (error.message === 'Network Error') {
          message.warning("Quý khách vui lòng kiểm tra kết nối mạng.");
        }
        // error.message = msg || "Có lỗi xảy ra. Vui lòng thử lại";
        // return Promise.reject({message: error.message, code: status});
      }
);
export default axiosClient;
