import { Button, Col, Form, Row, Space, theme, Table, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';
import { requiredItem } from '../../constants/constants';
import { getListTypeAction, reportTaskByStatus } from '../../services/apis';
import { filterOption } from '../../utils/funcHelper';

const ReportTaskByStatus = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataAction, setDataAction] = useState([]);
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'Type job',
            dataIndex: 'type_action_name',
            key: 'type_action_name',
            render: (text) => <p style={{paddingLeft: 12}}>{text}</p>
        },
        {
            title: 'Total',
            dataIndex: 'total_tasks',
            key: 'total_tasks',
            align: 'center',
            render: (text) => <strong>{text}</strong>
        },
        {
            title: 'Pending Assignment',
            dataIndex: 'pending_tasks',
            key: 'pending_tasks',
            align: 'center',
        },
        {
            title: 'Pending Assignment',
            dataIndex: 'pending_tasks',
            key: 'pending_tasks',
            align: 'center',
        },
        {
            title: 'Waiting Processing',
            dataIndex: 'waiting_tasks',
            key: 'waiting_tasks',
            align: 'center',
        },
        {
            title: 'Processing',
            dataIndex: 'processing_tasks',
            key: 'processing_tasks',
            align: 'center',
        },
        {
            title: 'Finished',
            dataIndex: 'completed_tasks',
            key: 'completed_tasks',
            align: 'center',
        },
        {
            title: 'Cancelled',
            dataIndex: 'cancelled_tasks',
            key: 'cancelled_tasks',
            align: 'center',
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };

    const getDataReport = async (startTime, endTime) => {
        const params = {
            startTime: startTime,
            endTime: endTime,
            type_action: form.getFieldValue('type_action') ?? null
        }
        setLoading(true);
        const res = await reportTaskByStatus(params);
        consoleLogUtil('getDataReport', res);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res.data]);
        } else {
            setData([]);
        }
    }
    const getDataTypeAction = async () => {
        try {
            const res = await getListTypeAction();
            if (res && res.status === 1) {
                setDataAction([...res?.data?.data?.map(item => ({ ...item, value: item?.id, label: item?.name }))]);
            } else {
                setDataAction([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const onFinish = async (dataForm) => {
        if (dataForm && dataForm?.date) {
            getDataReport(dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };

    useEffect(() => {
        getDataTypeAction();
    }, [])
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>

                    <Col span={7}>
                        <Form.Item
                            name={'type_action'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Loại công việc'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataAction}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={2}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record, index) => index?.toString()}
            />

            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportTaskByStatus);