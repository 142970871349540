import { Button, Col, Form, Input, Row, Space, theme, Table, message, Tag, Select, DatePicker, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { getListStaff, getListTaskForDesigner, getListTypeAction, updateDesignLinkForOrder, updateForMutilTask, updateSKUInfoToGoogleSheet, updateTaskById } from '../../services/apis';
import { DATE_FORMAT, STATUS_TASK, TYPE_MODAL } from '../../constants/constants';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, DoubleRightOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { checkUserPermissionSelectStaff, filterOption } from '../../utils/funcHelper';
import dayjs from 'dayjs';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import ModalTaskDetail from './modal/ModalTaskDetail';
import { useSelector } from 'react-redux';
import FooterTable from '../../components/FooterTable';
import ModalAssignTask from './modal/ModalAssignTask';

const ListTaskPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const userReducer = useSelector((state) => state?.userReducer?.data);
    const isPermissionSelectStaff = checkUserPermissionSelectStaff(userReducer);

    const initialValues = {
        keyword: '',
        staff: !isPermissionSelectStaff ? userReducer?.user_info?.id : null,
        store_name: '',
        date: [dayjs().startOf('day'), dayjs().endOf('day')]
    };
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [titleModal, setTitleModal] = useState('');
    const [dataStaff, setDataStaff] = useState([]);
    const [dataAction, setDataAction] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showModalAssignTask, setShowModalAssignTask] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); 
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            align: 'center',
        },
        {
            title: 'Origin ID',
            dataIndex: 'order_id',
            key: 'order_id',
            width: 90,
            align: 'center',
        },
        {
            title: <span style={{ paddingLeft: 12, paddingRight: 12 }}>Task name</span>,
            dataIndex: 'title_task',
            key: 'title_task',
            align: 'left',
            width: 200,
            render: (text, record, index) => <a style={{
                paddingLeft: 12, paddingRight: 12, wordWrap: "break-word",
                whiteSpace: "normal",
            }}>{text}</a>,
        },
        {
            title: <div style={{ paddingLeft: 12, paddingRight: 12 }}>Task description</div>,
            dataIndex: 'task_content',
            key: 'task_content',
            align: 'left',
            width: 300,
            render: (text, record, index) => <div style={{
                paddingLeft: 12, paddingRight: 12, wordWrap: "break-word",
                whiteSpace: "normal",
            }}>{text}</div>,
        },
        {
            title: <div style={{ paddingLeft: 12, paddingRight: 12 }}>Type work</div>,
            dataIndex: 'type_action_name',
            key: 'type_action_name',
            align: 'left',
            render: (text, record, index) => <div style={{ paddingLeft: 12, paddingRight: 12 }}>{text}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 160,
            render: (text, record, index) =>
                calculatorStatus(text)
        },
        {
            title: 'Assign to',
            dataIndex: 'assigned_user_fullname',
            key: 'assigned_user_fullname',
            align: 'center',
            width: 200,
            render: (text, record, index) => <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                {text}
            </div>,
        },
        {
            title: 'Create by',
            dataIndex: 'creator_fullname',
            key: 'creator_fullname',
            align: 'center',
            width: 200,
            render: (text, record, index) => <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                {text}
            </div>,
        },
        {
            title: isPermissionSelectStaff ? 'Create At' : 'Assign At',
            dataIndex: isPermissionSelectStaff ? 'createAt' : 'assignAt',
            key: isPermissionSelectStaff ? 'createAt' : 'assignAt',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <div style={{ fontSize: 12 }}>{dayjs(text).format('HH:mm:ss')}</div>
                <div style={{ fontSize: 12 }}>{dayjs(text).format(DATE_FORMAT.DD_MM_YYYY_FORMAT)}</div>
            </div>,
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };

    const statusOrderMap = {
        pending: { icon: <ClockCircleOutlined />, color: "#f6941c", text: "Pending Assignment" },
        processing: { icon: <SyncOutlined />, color: "processing", text: "Processing" },
        completed: { icon: <CheckCircleOutlined />, color: "success", text: "Finished" },
        cancelled: { icon: <CloseCircleOutlined />, color: "error", text: "Cancelled" },
        waiting: { icon: <ClockCircleOutlined spin />, color: "#b81c24", text: "Waiting Processing" },
    };

    const calculatorStatus = (txt) => {
        const status = statusOrderMap[txt] || statusOrderMap.pending;
        return <Tag icon={status.icon} color={status.color}>{status.text}</Tag>;
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newselectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${newselectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows([...selectedRows]);
            setSelectedRowKeys([...newselectedRowKeys]);
        }
    };

    const handleEditOrder = (record) => {
        setItemSelected(record);
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal('Chi tiết task');
    }

    const getDataStaff = async () => {
        try {
            const res = await getListStaff();
            consoleLogUtil('getListStaff', res);
            if (res && res.status === 1) {
                setDataStaff([...res?.data?.data?.filter(user => user.departments.includes("2"))?.map(item => ({ ...item, value: item?.id, label: item?.fullname }))]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getDataTasks = async (page, startTime, endTime, showLoading) => {
        try {
            const params = {
                keyword: form.getFieldValue('keyword') ?? '',
                status: form.getFieldValue('status') ?? null,
                staff_id: form.getFieldValue('staff') ?? null,
                type_action: form.getFieldValue('type_action') ?? null,
                startTime: startTime,
                endTime: endTime,
                isManager: userReducer?.user_info?.roles?.includes("4") ? 0 : 1, //Quản lý thì lọc theo createAt, còn nhân viên thì lọc theo assignAt
                limit: 50,
                currentPage: page
            }
            if (showLoading) setLoading(true);
            const res = await getListTaskForDesigner(params);
            if (showLoading) setLoading(false);
            consoleLogUtil('getListTaskForDesigner', res);
            if (res && res.status === 1) {
                setData([...res?.data?.data]);
                setTotalRecords(res?.data?.totalRows);
                setTotalPage(res?.data?.totalPages);
            } else {
                setData([]);
                setTotalPage(0);
                setTotalRecords(1);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }


    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataTasks(page - 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataTasks(value, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataTasks(page + 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const onFinish = async (dataForm) => {
        setCurrentPage(1);
        getDataTasks(1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    };

    const updateDesignLinkOrder = async (orderInfo) => {
        // call api update order
        try {
            const params = {
                id: orderInfo.order_item_id,
                design_link: orderInfo.link_design,
                mockup_front: orderInfo?.mockup_front ?? '',
                mockup_back: orderInfo?.mockup_back ?? '',
                updateBy: userReducer?.user_info?.id,
            }
            if (orderInfo?.status === STATUS_TASK[3].value) { //Nếu cập nhật task có trạng thái là hoàn thành thì update lại trạng thái tồn tại sku trên sheet
                params.is_exist = 1;
            }
            await updateDesignLinkForOrder(params);

        } catch (error) {
            console.log('error', error);
        }
    }

    const callAPIUpdateDataToGoogleSheet = async (taskInfo) => {
        try {
            let sizeAttribute = taskInfo?.size?.length > 0 ? taskInfo?.size : '';
            if (sizeAttribute && sizeAttribute.trim() !== "") {
                // Sử dụng regular expression để lấy hai phần đầu tiên trước ký tự "|"
                const match = sizeAttribute.match(/^([^|]+ \| [^|]+)/);
                if (match) {
                    sizeAttribute = match[1].trim();
                }
            }
            const params = {
                sku: taskInfo?.sku ?? '',
                size: sizeAttribute,
                sku_size: taskInfo?.size?.length > 0 ? taskInfo?.sku + "_" + sizeAttribute : taskInfo?.sku,
                design_link: taskInfo?.link_design ?? "",
                mockup_front: taskInfo?.mockup_front ?? "",
                mockup_back: taskInfo?.mockup_back ?? "",
                updateAt: dayjs().format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT_2),
                updateBy: userReducer?.user_info?.fullname
            }
            await updateSKUInfoToGoogleSheet(params);
        } catch (error) {
            console.log('error', error);
        }
    }

    const callApiUpdateTask = async (taskInfo) => {
        // call api update order
        try {
            const params = {
                id: taskInfo.id,
                department_id: 2,
                status: taskInfo?.status ?? null,
                assigned_user_id: taskInfo?.assigned_user_id ?? null,
                type_action: taskInfo?.type_action ?? null,
                start_time: taskInfo?.start_time ? dayjs(taskInfo.start_time).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null,
                end_time: taskInfo.end_time ? dayjs(taskInfo.end_time).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null,
                link_design: taskInfo?.link_design ?? null,
                mockup_front: taskInfo?.mockup_front ?? null,
                mockup_back: taskInfo?.mockup_back ?? null,
                note: taskInfo?.note ?? null,
                assignAt: taskInfo?.assignAt ? dayjs(taskInfo.assignAt).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT) : null,
                updateBy: userReducer?.user_info?.id
            }
            setLoading(true);
            const res = await updateTaskById(params);
            setLoading(false);
            if (res && res.status === 1) {
                if (taskInfo?.link_design && taskInfo?.link_design?.length > 0 && taskInfo.order_item_id) {
                    updateDesignLinkOrder(taskInfo);
                    if (taskInfo?.status === STATUS_TASK[3].value) { //Nếu cập nhật task có trạng thái là hoàn thành
                        callAPIUpdateDataToGoogleSheet(taskInfo);
                    }
                }
                message.success('Cập nhật task thành công');
                getDataTasks(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
            } else {
                message.error('Cập nhật task thất bại');
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const handleSaveAssignMutilTask = async (data) => {
        try {
            setShowModalAssignTask(false);
            consoleLogUtil('data', data);
            if (!data?.status && !data?.supplier_id) {
                message.error('Vui lòng chọn ít nhất 1 trạng thái hoặc 1 nhà cung cấp');
                return;
            }
            const params = {
                status: data?.status ?? 'waiting',
                ids: selectedRows.map(item => item?.id),
                start_time: data?.start_time ? dayjs(data?.start_time).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null,
                end_time: data?.end_time ? dayjs(data?.end_time).format(DATE_FORMAT.YYYYMMDD_FORMAT) : null,
                assigned_user_id: data?.assigned_user_id ?? null,
                type_action: data?.type_action ?? null,
                assignAt: data?.assignAt ? dayjs(data?.assignAt).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT) : null
            }
            setLoading(true);
            const res = await updateForMutilTask(params);
            setLoading(false);
            if (res && res.status === 1) {
                setSelectedRows([]);
                setSelectedRowKeys([]);
                message.success('Cập nhật thông tin thành công');
                getDataTasks(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
            } else {
                message.error('Cập nhật thông tin thất bại');
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            message.error('Cập nhật thông tin thất bại');
        }
    }

    const handleUpdateTask = (taskInfo) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            // call api add order
        } else {
            consoleLogUtil('taskInfo', taskInfo);
            callApiUpdateTask(taskInfo);
        }
    }

    const getDataTypeAction = async () => {
        try {
            const res = await getListTypeAction();
            if (res && res.status === 1) {
                setDataAction([...res?.data?.data?.map(item => ({ ...item, value: item?.id, label: item?.name }))]);
            } else {
                setDataAction([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        Promise.all([getDataStaff(), getDataTypeAction()]);
    }, [])

    useEffect(() => {
        if (form) {
            form.setFieldsValue(initialValues);
            getDataTasks(1, dayjs().startOf('day').format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs().endOf('day').format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
        }
    }, [form])


    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>

                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Từ khoá'} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="status"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn trạng thái'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={isPermissionSelectStaff ? STATUS_TASK : STATUS_TASK.filter(item => item.value !== 'pending')}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="staff"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhân viên'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataStaff}
                                disabled={checkUserPermissionSelectStaff(userReducer) ? false : true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="type_action"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn loại cv'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataAction}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                        >
                            <RangePicker
                                showTime
                                allowClear={false}
                                style={{ width: '100%', height: 40 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Space size="small">
                            <Tooltip title="Tìm kiếm">
                                <Button type="primary" htmlType="submit">
                                    <SearchOutlined />
                                </Button>
                            </Tooltip>
                            {
                                isPermissionSelectStaff ?
                                    <Tooltip title="Giao task hàng loạt">
                                        <Button onClick={() => setShowModalAssignTask(true)} disabled={selectedRows.length > 0 ? false : true}>
                                            <DoubleRightOutlined />
                                        </Button>
                                    </Tooltip> : null
                            }

                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                bordered
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                rowClassName={() => 'custom-row'}
                // scroll={{ x: 'max-content', y: 600 }}
                scroll={{ x: 'max-content'}}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            handleEditOrder(record);
                        }, // click row
                        onMouseEnter: event => {
                            event.currentTarget.style.cursor = 'pointer';
                        }, // hover row
                    };
                }}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <ModalTaskDetail
                visible={showModal}
                data={itemSelected}
                type={typeModal}
                title={titleModal}
                lstAction={dataAction}
                lstStaff={dataStaff}
                onCancel={() => setShowModal(false)}
                onSave={handleUpdateTask}
            />
            <ModalAssignTask
                visible={showModalAssignTask}
                lstAction={dataAction}
                lstStaff={dataStaff}
                onCancel={() => setShowModalAssignTask(false)}
                onSave={handleSaveAssignMutilTask}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListTaskPage);