import React from 'react';
import { Images } from '../../../assets';
import ItemLine from './ItemLine';
import { STATUS_ORDER } from '../../../constants/constants';


const ItemUserLog = ({ item, index }) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {
                index > 0 ? <ItemLine /> : null
            }
            
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                <img src={Images.ic_user_edit} style={{ width: 30, height: 30 }} />
                <span style={{ fontWeight: 600 }}>{item?.fullname ?? ''}</span>
                <span style={{ fontSize: 10, fontStyle: 'italic' }}>{item?.updateAt ?? ''}</span>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <span>{STATUS_ORDER.find(st => st.value === item?.old_status)?.label  ?? ''}</span>
                    <img src={Images.ic_arrow_right} style={{ width: 15, height: 15, marginLeft: 6, marginRight: 6 }} />
                    <span>{STATUS_ORDER.find(st => st.value === item?.new_status)?.label  ?? ''}</span>
                </div>
            </div>
        </div>
        
    );
}
export default ItemUserLog;