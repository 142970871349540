import { Button, Modal, Row, Space, Table, Tag } from "antd"
import React, { useState } from "react";
import { CloseCircleFilled, ShopOutlined, TagOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../settings/config";

const ModalSelectedOrder = ({ visible, onCancel, onDelete, data }) => {
    const columns = [
        {
            title: 'Act',
            align: 'center',
            width: 50,
            render: (text, record, index) => <a onClick={() => handleDelete(record.id)}><CloseCircleFilled style={{color: 'red'}}/></a>
        },
        {
            title: 'Order ID',
            dataIndex: 'order_key',
            key: 'order_key',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <a style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</a>
            </div>,
        },
        {
            title: 'Origin ID',
            dataIndex: 'order_id',
            key: 'order_id',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <a style={{ textDecorationLine: 'underline' }}>{text}</a>
            </div>,
        },
        {
            title: 'Image',
            dataIndex: 'image_src',
            key: 'image_src',
            align: 'center',
            width: 50,
            render: (text, record, index) =><div style={{padding: 6}}><img src={BASE_URL + text} style={{ width: 40, height: 40 }} /></div> ,
        },
        {
            title: <div style={{ paddingLeft: 20 }}>Product info</div>,
            align: 'left',
            width: 600,
            render: (text, record, index) => <div>
                <div style={{
                    paddingLeft: 12, paddingRight: 24, wordWrap: "break-word",
                    whiteSpace: "normal",
                }}><TagOutlined /> <strong>{record?.item_name ?? ''}</strong></div>
                
            </div>,
        },
        {
            title: 'Order status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 150,
        },
        {
            title: 'Store',
            dataIndex: 'store_name',
            key: 'store_name',
            align: 'center',
            width: 150,
            render: (text, record, index) => <div>
                <Tag icon={<ShopOutlined />} color="#3b5999">
                    {text}
                </Tag>
            </div>,
        },
        {
            title: 'Supplier',
            align: 'center',
            width: 200,
            render: (text, record, index) => <div style={{
                paddingLeft: 12, paddingRight: 12
            }}>
                {record?.suppiler_name ?? ''}
            </div>,
        },
        

    ];

    const handleDelete = (id) => {
        onDelete(id);
    }

    return (
        <Modal
            title={'List order selected'}
            open={visible}
            onCancel={onCancel}
            width={'70%'}
            footer={null}
        >
            <Table
                columns={columns}
                className="custom-table-task"
                style={{ marginTop: 12 }}
                dataSource={data}
                rowKey={record => record.id}
                scroll={{ x: 1300 }}
            />
            <Row gutter={16} justify={'end'}>
                <Space>
                    <Button onClick={onCancel}>
                        Close
                    </Button>
                </Space>
            </Row>
        </Modal>
    )
}
export default React.memo(ModalSelectedOrder);

