export const Images = {
    img_logo_app: require("../assets/icons/ic_logo_woo.png"),
    img_truck: require("./images/truck.png"),
    img_bill: require("./images/bill.png"),
    img_wood: require("./images/wood.png"),
    img_book: require("./images/book.png"),
    img_user: require("./images/user.png"),
    img_qr: require("./images/qr.png"),
    ic_required: require("./icons/ic_require.png"),
    ic_status: require("./icons/ic_accept.png"),
    ic_doctor: require("./icons/ic_doctor.png"),
    ic_empty: require("./icons/ic_empty.png"),
    ic_student: require("./icons/ic_student.png"),
    ic_add_student: require("./icons/ic_add_student.png"),
    ic_no_photo: require("./icons/ic_no_photo.png"),
    ic_shift: require("./icons/ic_shift.png"),
    ic_register: require("./icons/register.png"),
    ic_complete: require("./icons/ic_complete.png"),
    ic_surveyor: require("./icons/surveyor.png"),
    ic_user: require("./icons/ic_user.png"),
    ic_send: require("./icons/ic_send.png"),
    bg_feedback: require("./images/bg_feedback.jpg"),
    ic_car_parking: require("./icons/ic_car_parking.png"),
    ic_car_pos: require("./icons/ic_car_pos.png"),
    ic_car_complete: require("./icons/ic_car_complete.png"),
    ic_dollar: require("./icons/ic_dollar.png"),
    ic_dates: require("./icons/ic_dates.png"),
    ic_logo: require("./icons/ic_logo_woo.png"),
    ic_checked: require("./icons/ic_checked.png"),
    ic_waitting: require("./icons/ic_waiting_call.png"),
    ic_approved: require("./icons/ic_approved.png"),
    ic_cancelled: require("./icons/ic_cancelled.png"),
    ic_shipping: require("./icons/shipping.png"),
    ic_delivery_truck: require("./icons/delivery-truck.gif"),
    ic_user_log: require("./icons/ic_user_log.png"),
    ic_user_edit: require("./icons/ic_edit.png"),
    ic_change_status: require("./icons/ic_change_status.png"),
    ic_arrow_right: require("./icons/arrow_right.png"),
}